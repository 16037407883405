import { graphql } from 'gatsby';
import { withLang } from '../../utils/reactTools';
import {Stories} from '../stories';

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }

    markdownRemark(fileAbsolutePath: { regex: "/pages/stories.md/" }) {
      fields {
        locales {
          en {
            pageTheme
            ...SeoFragmentEN
            ...heroBannerFragmentEN
          }
          fr {
            pageTheme
            ...SeoFragmentFR
            ...heroBannerFragmentFR
          }
        }
      }
    }

    allStories: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "story" } } }
    ) {
      nodes {
        fields {
          locales {
            en {
              title
              weight
              showInListing
              category
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 1922
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
            fr {
              titleFR
              weight
              showInListing
              category
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 1922
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
          }
        }
      }
    }
  }
`;

export default withLang(Stories, 'fr');
